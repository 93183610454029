import { User, Payment, requestUser } from "./models";


export async function registerUser(user: requestUser): Promise<Response> {
    const response = await fetch("/register", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            nombre: user.first_mame,
            apellido: user.last_name,
            celular: user.tel,
            correo: user.email,
            tipo_documento: user.type_document,
            numero_documento: user.document
        })
    });

    return response;
}

export async function loginUser(identifier: string, isPhone: boolean): Promise<Response> {
    const response = await fetch("/request-login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            is_phone: isPhone,
            user_identifier: identifier
        })
    });
    return response;
}

export async function validateUserOTPLogin(identifier: string, code: string): Promise<Response> {
    const response = await fetch("/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            otp_code: code,
            user_identifier: identifier
        })
    });
    if (!response.ok) {
        throw new Error(`Error en la solicitud: ${response.status}`);
    }

    return response.json();
}

// export async function getUserInfo(): Promise<User[]> {
//     const response = await fetch('/.auth/me');
//     if (!response.ok) {
//         console.log("No identity provider found. Access to chat will be blocked.")
//         return [];
//     }

//     const payload = await response.json();
//     return payload;
// }

// export const fetchChatHistoryInit = async (): Promise<Conversation[] | null> => {


export const historyPayments = async (token: string): Promise<Payment[] | null> => {
    try {
        const response = await fetch("/payments", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            credentials: 'include'
        });

        if (!response.ok) {
            throw new Error("There was an issue fetching your data.");
        }

        const payload = await response.json();

        return payload;
    } catch (error) {
        console.error("There was an issue fetching your data:", error);
        return null;
    }
}

// export const registerUser = async (convId: string): Promise<ChatMessage[]> => {
//     const response = await fetch("/history/read", {
//         method: "POST",
//         body: JSON.stringify({
//             conversation_id: convId
//         }),
//         headers: {
//             "Content-Type": "application/json"
//         },
//     })
//     .then(async (res) => {
//         if(!res){
//             return []
//         }
//         const payload = await res.json();
//         let messages: ChatMessage[] = [];
//         if(payload?.messages){
//             payload.messages.forEach((msg: any) => {
//                 const message: ChatMessage = {
//                     id: msg.id,
//                     role: msg.role,
//                     date: msg.createdAt,
//                     content: msg.content,
//                 }
//                 messages.push(message)
//             });
//         }
//         return messages;
//     }).catch((err) => {
//         console.error("There was an issue fetching your data.");
//         return []
//     })
//     return response
// }



