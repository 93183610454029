import styles from "./Board.module.css";
import { getToken } from "../../api";
import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, SetStateAction, useState } from "react";


const Board = () => {
    
    const [userdata, setUserdata] = useState<any[]>();
    
    function handleTable(): void { 
        const userinfo: SetStateAction<Object[] | undefined> = []
        getToken().then((response) => {   
            let i=0; 
            let j=0;
            let k=0; 
            for(i=0; i<response.data.length; i++){ 
                k=i+1
                const obj = {
                    nombre: response.data[i],
                    token: response.data[k] 
                }
                userinfo[j] = obj
                j=j+1
                i=i+1
            }
            setUserdata(userinfo) 
            //console.log(userdata) 
        })
        .catch((err) => {
            console.log(err);
        })
    }

    return (
        <div>
         <h1>Preguntas realizadas por usuario</h1> 
         <table>
            <tr>
                <th>Nombre</th>
                <th>Preguntas</th>  
            </tr>   
            {userdata?.map(u => (
                <tr>
                    <td>{u.nombre}</td>
                    <td>{u.token}</td>
                </tr>
            ))}
         </table>        
         <button type="button" onClick={handleTable}>Actualizar</button>   

        </div>
    );   
                            
};

export default Board;
