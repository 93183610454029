import React, { useState, useRef, useEffect } from "react";
import styles from "./FormValidateOtp.module.css";
import { loginUser, validateUserOTPLogin } from "../../../api/user";
import { useAuth } from "../../../context/Auth";
import { toast } from "react-toastify";
import { Spinner } from "@fluentui/react";

interface Props {
  showModal: (opt: boolean) => void;
  isPhone: boolean,
  targetDirection: string
}

const FormValidateOtp: React.FC<Props> = ({ showModal, isPhone, targetDirection }: Props) => {
  const [codeInputs, setCodeInputs] = useState(["", "", "", "", "", ""]);
  const [notification, setNotification] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(600); 
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  // Function to focus next input field
  const focusNextInput = (index: number) => {
    const nextIndex = index + 1;
    if (nextIndex < codeInputs.length && inputRefs.current[nextIndex]) {
      inputRefs.current[nextIndex]?.focus();
    }
  };

  const handleCodeInputChange = (index: number, value: string) => {
    const newValue = value.slice(0, 1);    
    const newCodeInputs = [...codeInputs];
    newCodeInputs[index] = newValue;
    setCodeInputs(newCodeInputs);
    setIsButtonDisabled(newCodeInputs.some(input => input === ""));
    if (newValue.length === 1) {
      focusNextInput(index);
    }
  };

  const handleSubmit = async () => {
    // Lógica para manejar la verificación del código
    setLoading(true);
    try{
      const codeString = codeInputs.join("");
      const response = await login(targetDirection, codeString);
      if (response instanceof Error) {
        setLoading(false);
        toast.error('OTP incorrecto o expirado')
      } else {
        setLoading(false);
        toast.success('Inicio de sesion exitoso. Bienvenido')
        showModal(false)
      }
    }catch(error: any){
      console.error(error)
    }
  };

  const resendCode = async() => {
    try{
      const response = await loginUser(targetDirection, isPhone);
      if(response.status == 404){
        setLoading(false);
        toast.error('Usuario incorrecto, por favor revisalo o intenta con uno nuevo');
      }else if(response.status == 500){
        setLoading(false);
        toast.error('Error inesperado, intento de nuevo mas tarde');
      }else if(response.status == 200){
        setLoading(false);
        setTimeLeft(600);
        toast.success('Codigo enviado')
      }
      setLoading(false);
    }catch(error: any){
      setLoading(false);
      console.log(error)
      toast.error('Ha ocurrido un error')
    }
    
  };

  return (
    <div className={styles.formularioContainer}>
      <div className={styles.titleMethod}>
        <h3>Enviamos el código a tu {isPhone ? "celular" : "correo"}</h3>
        <p>{targetDirection}</p>
      </div>
      <div className={styles.row}>
        <div className={`${styles.anchoFlex}`}>
          {codeInputs.map((_, index) => (
            <input
              key={index}
              placeholder=""
              value={codeInputs[index]}
              onChange={(e) => handleCodeInputChange(index, e.target.value)}
              type="text"
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
      </div>

      <div className={styles.aditionalInfo}>
        <p className={styles.time}>Tu código vence en: <span>{formatTime(timeLeft)}</span></p>
        {timeLeft === 0 && <p className={styles.expired}>El código ha expirado</p>}
        <span onClick={resendCode} className={styles.resend}>Reenviar código</span>
      </div>

      <div className={styles.buttonsConfirm}>
        <button disabled={isButtonDisabled} className={`${!isButtonDisabled ? styles.validButton : ""}`} onClick={handleSubmit}>{loading && <Spinner/>}Verificar código</button>
      </div>
    </div>
  );
};

export default FormValidateOtp;
