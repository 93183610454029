import React, { useState } from "react";
import Styles from "./Login.module.css";
import mainIcon from "../../../assets/modal/logo.svg";
import FormLogin from "../../../components/forms/FormLogin/FormLogin";
import FormRegister from "../../../components/forms/FormRegister/FormRegister";
import FormValidateOtp from "../../../components/forms/FormValidateOtp/FormValidateOtp";
interface Props {
  showModal: (opt: boolean) => void;
}


const Login = ({ showModal } : Props) => {
  const [formType, setFormType] = useState("login");
  const [isPhone, setIsPhone] = useState(false);
  const [directionDevice, setDirectionDevice] = useState("31312");

  const renderForm = () => {
    switch (formType) {
      case "login":
        return <FormLogin resetPass={setFormType} showModal={showModal} selectDirection={setDirectionDevice} selectDevice={setIsPhone} isPhone={isPhone}/>;
      case "register":
        return <FormRegister resetPass={setFormType} showModal={showModal} selectDirection={setDirectionDevice} selectDevice={setIsPhone}/>;
      case "otp":
        return <FormValidateOtp showModal={showModal} isPhone={isPhone} targetDirection={directionDevice}/>;
      default:
        return null;
    }
  };

  return (
    <div className={Styles.modal}>
      <div className={Styles.modalContent}>
        {/* <span className={Styles.closeButton} onClick={() => showModal(false)}>
          x
        </span> */}
        <div className={Styles.imgSection}>
          <img src={mainIcon} alt="red juridica" />
        </div>
        <div className={Styles.main}>
          <h3 className={Styles.titleForm}>
            {formType === "login"
              ? "Inicia sesión"
              : formType === "register"
              ? "Registro"
              : ""}
          </h3>
          {/* <div className={Styles.iconsSocial}>
            <img className={Styles.IconGoogle} src={IconGoogle.src} />
            <img className={Styles.IconFb} src={IconFb.src} />
          </div> */}
          {renderForm()}
          <div className={Styles.LineFooter}>
            <p className={Styles.descLink}>
              {formType === "login"
                ? "¿Aun no tienes cuenta?"
                : formType === "register"
                ? "¿Ya tienes una cuenta?"
                : "¿Ya tienes una cuenta?"}
            </p>
            <a
              className={Styles.registerLink}
              href="#"
              onClick={() =>
                setFormType(
                  formType === "login"
                    ? "register"
                    : formType === "register"
                    ? "login"
                    : "login"
                )
              }
              id="register-link"
            >
              {formType === "login"
                ? "Regístrate aquí"
                : formType === "register"
                ? "Iniciar sesión"
                : "Iniciar sesión"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
