import React, { useState, useContext } from "react";
import styles from "./FormRegister.module.css";
// import AuthService from "@/services/auth/auth.service";
// import { verifyRecaptcha } from "../../../hooks/verifyRecaptcha";
// import Link from "next/link";
// import GlobalContext from "@/context/GlobalContext";
import { registerUser, requestUser } from "../../../api/user";
import { Spinner } from "@fluentui/react";
import { toast } from "react-toastify";


interface Props {
  showModal: (opt: boolean) => void;
  resetPass: (form: string) => void;
  selectDirection: (form: string) => void;
  selectDevice: (isPhone: boolean) => void;
}

const FormRegister = ({ showModal, resetPass, selectDevice, selectDirection }: Props) => {
  // const authObj = AuthService();
  // const {
  //   setErrorMsg,
  //   setLoading
  // } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [tel, setTel] = useState("");
  const [errorTel, setErrorTel] = useState("");  
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [terms, setTerms] = useState("");
  const [errorTerms, setErrorTerms] = useState("");
  const [typeDocument, setTypeDocument] = useState("");
  const [errorType, setErrorType] = useState("");
  const [document, setDocument] = useState("");
  const [errorDocument, setErrorDocument] = useState("");

  const handleFirstName = (event: any) => {
    setFirstName(event.target.value);
    setErrorFirstName("")
  }

  const handleLastName = (event: any) => {
    setLastName(event.target.value);
    setErrorLastName("")
  }

  const handleEmail = (event: any) => {
    setEmail(event.target.value);
    setErrorEmail("")
  }

  const handleTel = (event: any) => {
    setTel(event.target.value);
    setErrorTel("")
  }

  const handleTerms = (event: any) => {
    setTerms(event.target.checked);
    setErrorTerms("")
  }

  const handleType = (event: any) => {
    setTypeDocument(event.target.value);
    // setErrorTerms("")
  }

  const handleDocument = (event: any) => {
    setDocument(event.target.value);
    // setErrorTerms("")
  }

  const handleSubmit = async () => {
    try {
      if (!firstName) {
        setErrorFirstName("Campo obligatorio")
        return;
      } else {
        setErrorFirstName("")
      }

      if (!lastName) {
        setErrorLastName("Campo obligatorio")
        return;
      } else {
        setErrorLastName("")
      }

      if (!tel) {
        setErrorTel("Campo obligatorio")
        return;
      } else {
        setErrorTel("")
      }

      //Validación del campo email
      let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
      if (!email) {
        setErrorEmail("Campo obligatorio");
        return;
      } else if (!emailRegex.test(email)) {
        setErrorEmail("Email inválido");
        return;
      } else {
        setErrorEmail("");
      }

      if (typeDocument == "") {
        setErrorType("Campo obligatorio")
        return;
      } else {
        setErrorType("")
      }

      if (document == "") {
        setErrorDocument("Campo obligatorio")
        return;
      } else {
        setErrorDocument("")
      }

      if (!terms) {
        setErrorTerms("Debes aceptar el Tratamiento de Datos Personales y la Política de Privacidad para poder continuar")
        return;
      } else {
        setErrorTerms("")
      }

      /////////////////////////////////////////////
      // const recaptchaToken = await verifyRecaptcha();
      // if (!recaptchaToken) {
      //   throw new Error(
      //     "¡Hubo un error al verificar el reCAPTCHA, Inténtalo de nuevo!"
      //   );
      //   return;
      // }
      // ///////////////////////////////////////////

      setLoading(true);
      
      
      const data: requestUser = {
        first_mame: firstName,
        last_name: lastName,
        tel: tel,
        email: email,
        type_document: typeDocument,
        document: document,
      };
      const response = await registerUser(data);
      if(response.status == 200 || response.status == 201){
        selectDirection(tel);
        selectDevice(true);
        setLoading(false);
        resetPass('otp');
        toast.success('Registro exitoso.')
        // showModal();
      }else{
          toast.error('Error inesperado, intentelo de nuevo mas tarde');
      }
      setLoading(false);
      // setNotification(response.message);
    } catch (error: any) {
      console.log(error)
      toast.error('Ha ocurrido un error inesperado, por favor intentar de nuevo mas tarde.')
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorEmail(error.response.data.message);
      } 
    }
  };

  return (
    <div className={styles.formularioContainer}>
      <div className={styles.row}>
      <div className={`${styles.ancho50}`}>
        <div className={`${styles.campo} ${errorFirstName ? styles.error : ""}`}>

          <div className={styles.titulo}>Nombre</div>
          <input
            placeholder="Nombre"
            type="text"
            onChange={handleFirstName}
            value={firstName}
          />
        </div>
        <div className={styles.errorText}>{errorFirstName && errorFirstName}</div>
      </div>
      
      <div className={`${styles.ancho50}`}>
        <div className={`${styles.campo} ${errorLastName ? styles.error : ""}`}>
          <div className={styles.titulo}>Apellido</div>
          <input
            placeholder="Apellido"
            type="text"
            onChange={handleLastName}
            value={lastName}
          />
        </div>
        <div className={styles.errorText}>{errorLastName && errorLastName}</div>
      </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.anchoTotal}`}>
          <div className={`${styles.campo} ${errorTel ? styles.error : ""}`}>
            <div className={styles.titulo}>Celular</div>
            <input
              placeholder="Celular"
              onChange={handleTel}
              value={tel}
              type="text"
            />
          </div>

          <div className={styles.errorText}>{errorTel && errorTel}</div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.anchoTotal}`}>
          <div className={`${styles.campo} ${errorEmail ? styles.error : ""}`}>
            <div className={styles.titulo}>Correo electrónico</div>
            <input
              placeholder="Correo electrónico"
              onChange={handleEmail}
              value={email}
              type="email"
            />
          </div>

          <div className={styles.errorText}>{errorEmail && errorEmail}</div>
        </div>
      </div>

      <div className={styles.row}>
        <div className={`${styles.ancho50}`}>
          <div className={`${styles.campo} ${errorType ? styles.error : ""}`}>
            <div className={styles.titulo}>Tipo de documento</div>
            <select onChange={handleType} value={typeDocument}>
              <option value={""}>Tipo de documento</option>
              <option value={"CC"}>Cedula de ciudadania</option>
              <option value={"CE"}>Cedula de extrajeria</option>
              <option value={"Pasaporte"}>Pasaporte</option>
            </select>
          </div>

          <div className={styles.errorText}>{errorType && errorType}</div>
        </div>
      {/* </div>

      <div className={styles.row}> */}
        <div className={`${styles.ancho50}`}>
          <div className={`${styles.campo} ${errorDocument ? styles.error : ""}`}>
            <div className={styles.titulo}>N. documento</div>
            <input
              placeholder="Numero"
              onChange={handleDocument}
              type="text"
              value={document}
            />
          </div>

          <div className={styles.errorText}>{errorDocument && errorDocument}</div>
        </div>
      </div>

      <div className={styles.row}>
        <div className={`${styles.anchoTotal}`}>
          <div className={`${styles.terms} ${errorEmail ? styles.error : ""}`}>
          <input type="checkbox" id="terms" onChange={handleTerms} />
          <label htmlFor="terms">Al marcar esta casilla, confirmo que he leído y acepto el <a href={"/politicas-datos"}>Tratamiento de Datos Personales</a> y la <a href={"/politicas-privacidad"}>Política de Privacidad</a></label>
        </div>
        <div className={styles.errorText}>{errorTerms && errorTerms}</div>
      </div>
      </div>

      <div className={styles.buttonsConfirm}>
        <button onClick={handleSubmit}>{loading && <Spinner/>} Registrarme</button>
      </div>
    </div>
  );
};

export default FormRegister;
