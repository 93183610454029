import React, { useState } from "react";
import styles from "./FormLogin.module.css";
import { loginUser } from "../../../api/user";
import { toast } from 'react-toastify';
import { Spinner } from "@fluentui/react";

interface Props {
  showModal: (opt: boolean) => void;
  resetPass: (form: string) => void;
  selectDirection: (device: string) => void;
  selectDevice: (isPhone: boolean) => void;
  isPhone: boolean;
}

const FormLogin: React.FC<Props> = ({ showModal, resetPass, selectDevice, selectDirection, isPhone }) => {
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<"phone" | "email" | null>(null);
  const [showFields, setShowFields] = useState(false);
  const [phoneData, setPhoneData] = useState<{ countryCode: string; phoneNumber: string }>({
    countryCode: "+57",
    phoneNumber: "",
  });
  const [emailData, setEmailData] = useState<string>("");

  const handleContinueWithPhone = () => {
    setSelectedOption("phone");
    selectDevice(true);
    setShowFields(true);
  };

  const handleContinueWithEmail = () => {
    setSelectedOption("email");
    selectDevice(false);
    setShowFields(true);
  };

  const handleSubmit = async() => {
    setLoading(true);
    let target = "";
    if (selectedOption === "email"){
      selectDirection(emailData);
      target = emailData;
    }else if (selectedOption === "phone"){
      selectDirection(phoneData.phoneNumber);
      target = phoneData.phoneNumber;
    }

    try{
      const response = await loginUser(target, isPhone);
      if(response.status == 404){
        setLoading(false);
        toast.error('Usuario incorrecto, por favor revisalo o intenta con uno nuevo');
      }else if(response.status == 500){
        setLoading(false);
        toast.error('Error inesperado, intento de nuevo mas tarde');
      }else if(response.status == 200){
        setLoading(false);
        toast.success('Código enviado, si no lo ve, revise la carpeta de SPAM')
        resetPass('otp');
        // showModal();
      }
      setLoading(false);
    }catch(error: any){
      setLoading(false);
      console.log(error)
      toast.error('Ha ocurrido un error')
    }
    
  };

  const handlePassword = () => {
    resetPass("reset");
  };

  const handleSwitchMethod = () => {
    setSelectedOption(null);
    setShowFields(false);
  };

  const renderInputFields = () => {
    if (showFields) {
      if (selectedOption === "phone") {
        return (
          <>
            <div className={styles.titleMethod}>
              <h3>Ingresa tu numero de celular</h3>
              <p>Te enviaremos un codigo para confirmarlo</p>
            </div>
            <div className={`${styles.row}`}>
              <div className={`${styles.campo} ${styles.indicativo}`}>
                <div className={styles.titulo}>País</div>
                <input
                  placeholder="+57"
                  value={"+57"}
                  // onChange={(e) => setPhoneData({ ...phoneData, countryCode: e.target.value })}
                  type="text"
                />
              </div>
            
              <div className={`${styles.campo} ${styles.anchoFlex}`}>
                <div className={styles.titulo}>Número de teléfono</div>
                <input
                  placeholder="Número de teléfono"
                  value={phoneData.phoneNumber}
                  onChange={(e) => setPhoneData({ ...phoneData, phoneNumber: e.target.value })}
                  type="text"
                />
              </div>
            </div>
            <div className={styles.buttonsConfirm}>
              <button onClick={handleSubmit}>{loading && <Spinner/>}Recibir codigo por SMS</button>
            </div>
          </>
        );
      } else if (selectedOption === "email") {
        return (
          <>
          <div className={styles.titleMethod}>
              <h3>Por favor, ingresa y valida tu correo</h3>
              <p>Te enviaremos un codigo para confirmarlo</p>
            </div>
          <div className={styles.row}>
            <div className={`${styles.campo} ${styles.anchoTotal}`}>
              <div className={styles.titulo}>Correo electrónico</div>
              <input
                placeholder="Correo electrónico"
                value={emailData}
                onChange={(e) => setEmailData(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className={styles.buttonsConfirm}>
              <button onClick={handleSubmit}>{loading && <Spinner/>}Recibir codigo por Correo</button>
            </div>
        </>
        );
      }
    }
    return null;
  };

  return (
    <div className={styles.formularioContainer}>
      {!showFields && (<>
        <div className={styles.choiceButtons}>
          <button className={styles.buttonTel} onClick={handleContinueWithPhone}>Continuar con tu celular</button>
          <button className={styles.buttonEmail} onClick={handleContinueWithEmail}>Continuar con tu correo</button>
        </div>
        {/* <Divider>Text</Divider> */}
        </>
      )}

      {renderInputFields()}

      {showFields && (
        <div className={styles.switchMethod}>
          <p onClick={handleSwitchMethod}>Cambiar de método</p>
        </div>
      )}

    </div>
  );
};

export default FormLogin;
