import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { validateUserOTPLogin } from '../api/user';
import { toast } from 'react-toastify';

// tipo usuario
interface User {
  token: string;
  refresh_token: string;
  user: {
    email: string,
    name: string
  };
}

// Definimos el tipado para contexto de autenticación
interface AuthContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<any>;
  logout: () => void;
  isAuthenticated: boolean;
}

// Creamos nuestro contexto de autenticación
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Hook personalizado para acceder al contexto de autenticación
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe ser usado dentro de un AuthProvider');
  }
  return context;
};

type AppProps = {
  children: ReactNode;
};

// Proveedor de autenticación
export const AuthProvider: React.FC<AppProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Verificar si hay un objeto user almacenado al cargar la aplicación
    const user = localStorage.getItem('user');
    setLoading(false);
    if (user) {
      validar(user);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    
  
    const validateAccessToken = (token: string, refresh_token: string) => {
      fetch('/auth/validate_access_token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
      })
      .then(response => {
        if (response.ok) {
          // El token de acceso es válido, no es necesario hacer nada más
        } else if (response.status === 401) {
          // El token de acceso no es válido, necesitamos refrescarlo
          fetch('/auth/token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ refresh_token: refresh_token })
          })
          .then(response => {
            if (response.ok) {
              return response.json();
            } else if (response.status === 401) {
              // El token de actualización también ha expirado, mostrar mensaje de sesión caducada
              toast.warning('Su sesión ha expirado. Por favor inicie sesión.');
              logout();
            } else {
              throw new Error('Error refreshing access token');
            }
          })
          .then(data => {
            if (data) {
              localStorage.setItem('user', JSON.stringify(data));
              setUser({
                token: data.token,
                refresh_token: data.refresh_token,
                user: {
                  name: data.user.name,
                  email: data.user.email
                }
              });
            }
          })
          .catch(error => {
            console.error(error);
          });
        } else {
          throw new Error('Error validating access token');
        }
      })
      .catch(error => {
        console.error(error);
      });
    };
  
    const runValidation = () => {
      const userLocal = localStorage.getItem('user');
      if (userLocal) {
        const userJson = JSON.parse(userLocal);
        if ('user' in userJson && 'token' in userJson && 'refresh_token' in userJson) {
          validateAccessToken(userJson.token, userJson.refresh_token);
        }
      }
    };
  
    // Validar el token de acceso cada 10 minutos 
    const intervalId = setInterval(runValidation, 600000);
  
    // Ejecutar la validación del token de acceso inmediatamente
    runValidation();
  
    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, []);
  
  

  const login = async (identifier: string, code: string) => {
    try {
      const response = await validateUserOTPLogin(identifier, code);
      // Almacena el token en localStorage
      localStorage.setItem('user', JSON.stringify(response));
      validar(JSON.stringify(response));
      return response;
    } catch (error) {
      console.error('Error al hacer login:', error);
      return error;
    }
  };

  const logout = () => {
    // Elimina el token y el usuario del localStorage y del estado
    localStorage.removeItem('user');
    setUser(null);
  };

  // const validarToken = async (token: string) => {
  //   try {
  //     // Decodificar el token utilizando la clave secreta del servidor
  //     const decodedToken = jwt_decode<User>(token, { complete: true });

  //     // Guarda la información del usuario en el estado
  //     setUser(decodedToken.payload);
  //   } catch (error) {
  //     console.error('Error al validar el token:', error);
  //     // Si el token no es válido, realiza logout
  //     logout();
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const validar = (objet: string) => {
    const userJson = JSON.parse(objet);
    if('user' in userJson && 'token' in userJson){
      setUser(userJson);
      setLoading(false);
    }
  }

  // valor del contexto
  const contextValue: AuthContextType = {
    user,
    login,
    logout,
    isAuthenticated: !!user,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

