import Styles from "./TablePayments.module.css";
import { Payment } from "../../../api/user/models";

interface Props {
  data: Payment[] | null;
}

const estadoTransaccion = (state: number | null) => {
  switch (state) {
    case 1:
      return "Aprobado";
    case 2:
      return "Rechazado";
    case 3:
      return "Expirada";
    case 4:
      return "Pendiente";
    default:
      return "";
  }
}

const TablePayments = ({ data }: Props ) => {
  return (
    <div className={Styles.tablePayments}>
      <table className={Styles.tableRow}>
        <thead>
          <tr>
            <th>Referencia</th>
            <th>Estado</th>
            <th>Fecha</th>
            <th>Monto</th>
            <th>Cantidad</th>
            <th>Descripción</th>
            <th>Factura</th>
          </tr>
        </thead>
        <tbody>
          {/* <tr>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
            <td>3</td>
          </tr> */}
          {data?.map((payment) => (
            <tr key={payment.referencia}>
              <td>{payment.referencia}</td>
              <td>{estadoTransaccion(payment.estado)}</td>
              <td>{payment.fecha}</td>
              <td>{payment.monto}</td>
              <td>{payment.cantidad_items}</td>
              <td>{payment.descripcion}</td>
              <td><a href={"data:image/pdf;base64,"+payment.pdf} title="Descargar" download="factura.pdf">{payment.factura}</a></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablePayments;
